// eslint-disable-next-line wix-custom-rules/wixstores-file-names
import {LocaleKeys} from '../../locale-keys/LocaleKeys';

interface AddressObject {
  addressLine1: string;
  addressLine2?: string;
  country: string;
  subdivision?: string;
  zipCode: string;
  city: string;
}
export const getFormattedAddressTranslation = (
  {addressLine1, addressLine2, city, country, subdivision, zipCode}: AddressObject,
  localeKeys: LocaleKeys
) => {
  if (addressLine2 && subdivision) {
    return localeKeys.thankYouPage.addressFormat.line2.withSubdivision({
      addressLine1,
      addressLine2,
      city,
      zipCode,
      country,
      subdivision,
    });
  }
  if (addressLine2) {
    return localeKeys.thankYouPage.addressFormat.line2.withoutSubdivision({
      addressLine1,
      addressLine2,
      city,
      zipCode,
      country,
    });
  }
  if (subdivision) {
    return localeKeys.thankYouPage.addressFormat.line1.withSubdivision({
      addressLine1,
      city,
      zipCode,
      country,
      subdivision,
    });
  }
  return localeKeys.thankYouPage.addressFormat.line1.withoutSubdivision({
    addressLine1,
    city,
    zipCode,
    country,
  });
};

export const isAddressEmpty = ({
  addressLine1,
  addressLine2,
  city,
  country,
  subdivision,
  zipCode,
}: AddressObject): boolean => {
  return !addressLine1 && !addressLine2 && !city && !country && !subdivision && !zipCode;
};
