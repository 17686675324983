import React from 'react';
import {Totals} from '@wix/wixstores-client-common-components/dist/es/src/Subtotals/Totals';
import {useControllerProps} from '../../ControllerContext';
import {BuyerNoteSection} from './BuyerNoteSection/BuyerNoteSection';
import * as s from './TotalsSection.scss';
import {classes} from './TotalsSectionOverride.st.css';
import {useTranslation} from 'yoshi-flow-editor-runtime';
import {getPlanFrequencyByDurationTranslationKey} from '../../../order.helper';
import settingsParams from '../../../settingsParams';
import {useSettings} from 'yoshi-flow-editor-runtime/tpa-settings/react';
import {Divider} from 'wix-ui-tpa/Divider';
import {useLocaleKeys} from '../../../LocaleKeysProvider';
import {shorten} from './utils';

export enum TotalsSectionDataHook {
  root = 'TotalsSectionDataHook.root',
  buyerNoteWrapper = 'TotalsSectionDataHook.buyerNoteWrapper',
  totalsWrapper = 'TotalsSectionDataHook.totalsWrapper',
  paymentStatus = 'TotalsSectionDataHook.paymentStatus',
  paymentStatusPaid = 'TotalsSectionDataHook.paymentStatusPaid',
  paymentStatusBalance = 'TotalsSectionDataHook.paymentStatusBalance',
  buyerNoteLabel = 'TotalsSectionDataHook.buyerNoteLabel',
  buyerNote = 'TotalsSectionDataHook.buyerNote',
}

export function TotalsSection() {
  const {t} = useTranslation();
  const localeKeys = useLocaleKeys();
  const {get} = useSettings();
  const {
    isOfflinePayment,
    formattedBalance,
    formattedTaxPrice,
    formattedShippingPrice,
    formattedSubTotalPrice,
    formattedTotalPrice,
    formattedDiscount,
    couponCode,
    isSubscription,
    subscriptionFrequency,
    isPickupSelected,
    shippingPrice,
    formattedTotalGiftCardAmount,
    hasGiftCard,
    totalGiftCardAmount,
    taxName,
  } = useControllerProps().thankYouPageStore;

  const getFormattedSubscriptionPrice = () => {
    const frequencyUnit = t(getPlanFrequencyByDurationTranslationKey(subscriptionFrequency));
    return t('THANK_YOU_PAGE_PLAN_FREQUENCY_DESCRIPTION', {
      price: formattedTotalPrice,
      frequencyUnitSingular: frequencyUnit,
    });
  };

  const getTotalTitle = () => {
    return get(settingsParams.THANK_YOU_PAGE_TOTAL_COST_LABEL) ?? localeKeys.thankYouPage.total.label();
  };

  const getShippingTitle = () => {
    return isPickupSelected ? localeKeys.thankYouPage.pickup.label() : localeKeys.thankYouPage.shipping.label();
  };

  const getShippingValue = () => {
    return shippingPrice === 0 ? localeKeys.thankYouPage.deliveryMethod.free.label() : formattedShippingPrice;
  };

  return (
    <div className={s.root} data-hook={TotalsSectionDataHook.root}>
      <BuyerNoteSection />
      <div className={s.totals}>
        <div className={s.section} data-hook={TotalsSectionDataHook.totalsWrapper}>
          <Totals>
            <Totals.SubtotalRow
              title={localeKeys.thankYouPage.subtotal.label()}
              value={formattedSubTotalPrice}
              className={classes.mySubtotalRow}
            />
            <Totals.SubtotalRow
              title={getShippingTitle()}
              value={getShippingValue()}
              className={classes.mySubtotalRow}
            />
            <Totals.SubtotalRow
              title={taxName || localeKeys.thankYouPage.tax.label()}
              value={formattedTaxPrice}
              className={classes.mySubtotalRow}
            />
            {!!couponCode && (
              <Totals.SubtotalRow
                title={localeKeys.thankYouPage.promoCode.label({couponCode: shorten(couponCode, 10, '...')})}
                value={`-${formattedDiscount}`}
                className={classes.mySubtotalRow}
              />
            )}
            {!!hasGiftCard && !!totalGiftCardAmount && totalGiftCardAmount > 0 && (
              <Totals.SubtotalRow
                title={localeKeys.thankYouPage.giftCard.label()}
                value={`-${formattedTotalGiftCardAmount}`}
                className={classes.mySubtotalRow}
              />
            )}
            <Totals.SummeryDivider className={classes.mySummeryDivider} />
            <Totals.TotalRow
              className={classes.myTotalRow}
              title={getTotalTitle()}
              value={isSubscription ? getFormattedSubscriptionPrice() : formattedTotalPrice}
            />
          </Totals>
        </div>
        {isOfflinePayment && (
          <>
            <Divider className={s.divider} />
            <Totals>
              <Totals.SubtotalRow
                title={localeKeys.thankYouPage.paid.label()}
                value={'0'}
                className={classes.mySubtotalRow}
              />
              <Totals.SubtotalRow
                title={localeKeys.thankYouPage.dueLater.label()}
                value={formattedBalance}
                className={classes.mySubtotalRow}
              />
            </Totals>
          </>
        )}
      </div>
    </div>
  );
}
