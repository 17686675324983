import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {BillingColumn} from './BillingColumn';
import {ShippingColumn} from './ShippingColumn';
import * as s from './ShippingSection.scss';
import {isAddressEmpty} from '../../../addressUtils';

export enum ShippingSectionDataHook {
  root = 'ShippingSectionDataHook.root',
}

export function ShippingSection() {
  const {billingAddress, address: shippingAddress} = useControllerProps().thankYouPageStore;

  const isShippingAddressEmpty = isAddressEmpty({
    addressLine1: shippingAddress.addressLine,
    addressLine2: shippingAddress.addressLine2,
    city: shippingAddress.city,
    country: shippingAddress.country,
    subdivision: shippingAddress.subdivision,
    zipCode: shippingAddress.zipCode,
  });
  const isBillingAddressEmpty = isAddressEmpty({
    addressLine1: billingAddress.addressLine1,
    addressLine2: billingAddress.addressLine2,
    city: billingAddress.city,
    country: billingAddress.country,
    subdivision: billingAddress.subdivision,
    zipCode: billingAddress.zipCode,
  });

  return (
    <div className={s.section} data-hook={ShippingSectionDataHook.root}>
      {!isShippingAddressEmpty && (
        <Column>
          <ShippingColumn />
        </Column>
      )}
      {!isBillingAddressEmpty && (
        <Column>
          <BillingColumn />
        </Column>
      )}
    </div>
  );
}

const Column = (props: any) => {
  return <div className={s.column}>{props.children}</div>;
};
