import React from 'react';
import {Text} from 'wix-ui-tpa/Text';
import {classes} from './ShippingSectionOverride.st.css';

export interface ShippingSectionTextProps {
  dataHook: string;
  text: string;
  isTitle?: boolean;
}

export const ShippingSectionText: React.FC<ShippingSectionTextProps> = ({dataHook, text, isTitle = false}) => {
  return (
    <div className={isTitle ? classes.wrapper : ''}>
      <Text className={isTitle ? classes.title : classes.text} data-hook={dataHook}>
        {text}
      </Text>
    </div>
  );
};
