import React from 'react';
import {useStyles} from 'yoshi-flow-editor-runtime/tpa-settings/react';
import {LocaleKeysProvider} from '../../LocaleKeysProvider';
import {ContinueShopping} from '../Components/ContinueShopping/ContinueShopping';
import {Header} from '../Components/Header/Header';
import {Layout} from '../Components/Layout/Layout';
import stylesParams from '../../stylesParams';

export enum ThankYouPageContentDataHook {
  content = 'ThankYouPageAppDataHook.content',
}

export function PageContent() {
  const {get} = useStyles();
  const shouldRenderContinueShoppingLink = get(stylesParams.THANK_YOU_PAGE_CONTINUE_SHOPPING_LINK_SELECTION);

  return (
    <LocaleKeysProvider>
      <div data-hook={ThankYouPageContentDataHook.content}>
        <Header />
        <Layout />
        {shouldRenderContinueShoppingLink && <ContinueShopping />}
      </div>
    </LocaleKeysProvider>
  );
}
