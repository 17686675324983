import React from 'react';
import {useControllerProps} from '../../../ControllerContext';
import {Text} from 'wix-ui-tpa/Text';
import * as s from '../TotalsSection.scss';
import {TotalsSectionDataHook} from '../TotalsSection';
import {useSettings} from 'yoshi-flow-editor-runtime/tpa-settings/react';
import settingsParams from '../../../../settingsParams';
import {classes} from './BuyerNoteSectionOverride.st.css';

export const BuyerNoteSection = () => {
  const {buyerNote} = useControllerProps().thankYouPageStore;
  const {get} = useSettings();

  const getBuyerNoteTitle = () => {
    return get(settingsParams.THANK_YOU_PAGE_NOTE_LABEL) as string;
  };
  return (
    <div className={s.section} data-hook={TotalsSectionDataHook.buyerNoteWrapper}>
      {buyerNote && (
        <>
          <div>
            <Text className={classes.noteTitle} data-hook={TotalsSectionDataHook.buyerNoteLabel}>
              {getBuyerNoteTitle()}
            </Text>
          </div>
          <div>
            <Text className={classes.noteText} data-hook={TotalsSectionDataHook.buyerNote}>
              {buyerNote}
            </Text>
          </div>
        </>
      )}
    </div>
  );
};
